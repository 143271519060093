







import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DefinitionsList extends Vue {
  @Prop(Object) definitions!: { [key: string]: string };
}
